import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities } from './cons-cloture-motif-type.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IConsClotureMotifType } from 'app/shared/model/cons-cloture-motif-type.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
export const ConsClotureMotifType = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const consClotureMotifTypeList = useAppSelector(state => state.consClotureMotifType.entities);
  const loading = useAppSelector(state => state.consClotureMotifType.loading);

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  const { match } = props;

  return (
    <div>
      <h2 id="cons-cloture-motif-type-heading" data-cy="ConsClotureMotifTypeHeading">
        <Translate contentKey="almobadaraMasterDataApp.consClotureMotifType.home.title">Cons Cloture Motif Types</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="almobadaraMasterDataApp.consClotureMotifType.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="almobadaraMasterDataApp.consClotureMotifType.home.createLabel">
              Create new Cons Cloture Motif Type
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {consClotureMotifTypeList && consClotureMotifTypeList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.consClotureMotifType.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.consClotureMotifType.code">Code</Translate>
                </th>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.consClotureMotifType.name">Name</Translate>
                </th>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.consClotureMotifType.nameAr">Name Ar</Translate>
                </th>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.consClotureMotifType.nameEn">Name En</Translate>
                </th>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.consClotureMotifType.status">Status</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {consClotureMotifTypeList &&
                consClotureMotifTypeList.map((consClotureMotifType, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`${match.url}/${consClotureMotifType.id}`} color="link" size="sm">
                        {consClotureMotifType.id}
                      </Button>
                    </td>
                    <td>{consClotureMotifType.code}</td>
                    <td>{consClotureMotifType.name}</td>
                    <td>{consClotureMotifType.nameAr}</td>
                    <td>{consClotureMotifType.nameEn}</td>
                    <td>
                      <Translate contentKey={`almobadaraMasterDataApp.Status.${consClotureMotifType.status}`} />
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${consClotureMotifType.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${consClotureMotifType.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${consClotureMotifType.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Archive</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="almobadaraMasterDataApp.consClotureMotifType.home.notFound">
                No Cons Cloture Motif Types found
              </Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ConsClotureMotifType;
